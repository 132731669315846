<template>
  <nav class="bg-gray-200">
    <div class="max-w-7xl sm:px-6 lg:px-8 px-2 mx-auto">
      <div class="relative flex items-center justify-between h-16">
        <div class="flex items-center flex-1">
          <div class="flex items-center flex-shrink-0 text-lg font-semibold text-gray-200">
            <Link href="/" class="hover:contrast-125 hover:brightness-75 transition-all drop-shadow-sm">
              <img :src="logo" class="w-auto h-10" :alt="settings.name" />
            </Link>
          </div>
          <div class="sm:block sm:ml-3 self-end hidden">
            <span class="md:flex hidden px-2 space-x-4 font-mono text-sm text-gray-200">
              {{ slogan }}
            </span>
          </div>
        </div>
        <div class="sm:static sm:inset-auto sm:ml-6 sm:pr-0 absolute inset-y-0 right-0 flex items-center pr-2">
          <Button variant='link' :active="locale == 'en'" :href="localePath('en')">EN</Button>
          <Button variant='link' :active="locale == 'de'" :href="localePath('de')">DE</Button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Link } from "@inertiajs/inertia-vue3"
import { Button } from "@/elements"
import { computed } from "vue"
import { logo } from '@/utils/logos'
import settings from "@/generated/settings.json"

// current locale:
import { useI18n } from "vue-i18n"

const locale = useI18n().locale

const slogan = settings.slogan_de

const localePath = (locale: string) => {
  return `${window.location.pathname}?locale=${locale}`
}

const isActive = (path: string) => {
  return window && path === window.location.pathname
}
// const activeClasses = "ring-2 ring-offset-2 ring-offset-gray-800 ring-white"
</script>
