import "~/style.css"

import { startApp, removeModulePrefix } from "~/startApp"
import Layout from '~/components/jobs4i/Layout.vue'
import i18n from '~/utils/i18n'

const defaultModules = removeModulePrefix(import.meta.glob(`../pages/default/**/*.vue`))
const siteModules = removeModulePrefix(import.meta.glob(`../pages/jobs4i/**/*.vue`))

const modules = {
  ...defaultModules,
  ...siteModules,
}
startApp(modules, Layout, i18n)


